'use client';

import { BKGROUND_IMAGE } from '@/constants/general';

const Page = () => {
    const backgroundImage = `url(${BKGROUND_IMAGE})`;

    // Get the client's IP address from the request headers
    // const ip = headers().get('x-forwarded-for');
    // console.log(headers());

    return (
        <>
            {/* <p>
                IP Address:
                {` ${ip}` || ' Not found'}
            </p> */}
            <div
                className="relative flex flex-col items-center justify-center bg-cover bg-no-repeat"
                style={{
                    backgroundImage,
                    minHeight: '90vh',
                }}
            ></div>
        </>
    );
};

export default Page;
